export const menuItems = [
  {
    path: "#top-page",
    text: "home",
  },
  {
    path: "#about",
    text: "About",
  },
  {
    path: "#insta",
    text: "Instagram",
  },
  {
    path: "/contact",
    text: "contact",
  },
]
